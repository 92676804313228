<template>
    <v-container fluid>
        <PageLayout>
            <template>
                <v-form @submit.prevent="getStations">
                    <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'station.create'}" color="indigo" fab fixed top right class="v-btn--add-form-top white--text">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                        <div>
                            <div class="py-0">
                                <TabsNav/>
                                <v-row>
                                    <v-col cols="12" sm="8" class="d-flex align-center mb-10">
                                        <h1 class="mr-4">{{ $t('search_station') }}</h1>
                                        <v-icon color="primary" @click="filter_show===true ? filter_show=false : filter_show=true">mdi-filter-plus</v-icon>
                                    </v-col>
                                    <v-col v-if="!$vuetify.breakpoint.xsOnly" v-show="!filter_show" cols="12" sm="4" class="text-right">
                                        <v-btn :to="{name: 'station.create'}" class="infinity_button" color="primary">
                                            {{ $t('create') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row v-if="filter_show">
                                    <v-col class="py-0" cols="12" sm="2">
                                        <ValidationProvider ref="code" rules="min:1|max:30"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="code" type="text" :error-messages="errors"
                                                          :label="$t('code')"
                                                          clearable
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="4">
                                        <ValidationProvider ref="country" v-slot="{ errors, valid }">
                                            <v-select v-model="country" :error="!valid" :error-messages="errors"
                                                      :items="countryItems"
                                                      item-text="name" item-value="id"
                                                      hide-details
                                                      :label="$t('country')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                            </v-select>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="station_name" rules="min:2|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="station_name" type="text" :error-messages="errors"
                                                          :label="$t('station_name')"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </div>

                            <div class="px-4 py-4" v-if="filter_show">
                                <v-row>
                                    <v-col cols="12" sm="3" md="2">
                                        <v-select :value="perPage" :items="perPageItems"
                                                  :label="$t('items_per_page')"
                                                  @input="perPage = options.itemsPerPage = Number($event)"
                                                  hide-details
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  :disabled="loading"
                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="9" md="10" class="d-flex justify-center justify-sm-end align-self-center">
                                        <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">{{ $t('search') }}</v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </ValidationObserver>
                </v-form>

                <ResizableTable
                    class="main-data-table"
                    :rows="stationItems"
                    :columns="headers.map((item) => {
                        return {
                            ...item,
                            active: true,
                            prop: item.value,
                            label: item.text,
                        }
                    })"
                >
                    <template v-slot:name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            <span class="cursor-pointer font_weight_600" @click="editStation(item)">
                                {{ item.name }}
                                {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                            </span>
                            <v-icon v-if="!item.deleted" @click="deleteStation(item)" small>mdi-delete-outline</v-icon>
                        </div>
                    </template>
                </ResizableTable>

                <v-pagination
                    v-show="totalStations > perPage"
                    v-model="page"
                    :total-visible="totalVisiblePag"
                    class="pagination_table"
                    :length="pageCount"
                    :disabled="loading"
                ></v-pagination>
            </template>
        </PageLayout>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex"
import PageLayout from "@/components/Leentech/PageLayout.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";
import FilterView from "@/plugins/mixins/FilterView";

export default {
    name: "Stations",
    components: {
        TabsNav,
        PageLayout,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            code: null,
            station_name: null,
            sortBy: "name",
            sortDir: true,
            loading: false,
            options: {
                ...FilterView.data().options,
                sortBy: ['name']
            },
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 7,
            totalStations: 0,
            stationItems: [],
            country: null,
            countryItems: [],
            headers: [
                {
                    text: this.$t('name'),
                    align: "left",
                    value: "name"
                },
                {
                    text: this.$t('code'),
                    align: "left",
                    value: "code"
                },
                {
                    text: this.$t('country'),
                    align: "left",
                    value: "country_id"
                },
            ],
            filter_show: false,
        }
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
    },
    async mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage;
        await this.getCountries()
        await this.getStations()
    },
    watch: {
        options: {
            handler() {
                this.getStations()
            },
            deep: false
        }
    },
    methods: {
        rowClass() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table3' : 'rowMobiClass';
        },
        editStation(item) {
            this.$router.push({
                name: 'station.edit',
                params: {
                    id: item.id
                }
            })
        },
        async getStations() {
            var _this = this
            this.progress = 0
            this.loading = true
            const {
                sortBy,
                sortDir: sortDesc,
                page,
                itemsPerPage
            } = this.options
            let params = {}

            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }

            if (sortDesc !== undefined) {
                params.sortDir = sortDesc ? 'asc' : 'desc'
            }

            if (page !== undefined) {
                params.page = page
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage
            }
            if (this.station_name) {
                params.station_name = this.station_name
            }
            if (this.code) {
                params.code = this.code
            }
            if (this.country) {
                params.country = this.country
            }


            await this.$http
                .get("admin/station", {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.stationItems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalStations = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.$toastr.success(this.$t('failed_to_get_list_stations'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async deleteStation(item) {
            if (confirm(this.$t('delete_station'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`admin/station/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('station_has_been_deleted'))
                        this.getStations()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('station_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async getCountries() {
            var _this = this
            let params = {}
            params.list = 'all'
            await this.$http.get(`admin/country`, {
                    params: params,
                }
            )
                .then(res => {
                    this.countryItems = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_countries'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
        },

    }
}
</script>
